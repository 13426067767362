import React, { useEffect, useState } from "react";
import {
  Button,
  Chip,
  CircularProgress,
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import _ from "lodash";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import DialogCommonDefault from "../../../../components/dialogs/DialogCommonDefault";
import { buttonColors } from "../../../../utils/enum";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import { Autocomplete } from "@material-ui/lab";
import { CustomTheme } from "../../../../types/customTheme";
import SwitchCommon from "../../../../components/switches/SwitchCommon";
import TextfieldDefaultNew from "../../../../components/textField/TextfieldDefaultNew";
import ValidationMessage from "../../../../components/validation/ValidationMessage";
import { EMAIL_PATTERN } from "../../../../utils/consts";
import { useRouteMatch } from "react-router-dom";
import { version } from "process";
import CardCommon from "../../../../components/card/CardCommon";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    autoComplete: {
      marginTop: "4px",
      "& .MuiAutocomplete-input": {
        color: theme.palette.custom.orange.contrastText,
      },
      "& .MuiInputBase-root": {
        color: "inherit",
        backgroundColor: theme.palette.background.entity_background,
        borderRadius: 10,
      },
      [`& fieldset`]: {
        borderRadius: 10,
        border: "none",
        color: theme.palette.custom.orange.contrastText,
      },
    },
    titleColor: {
      color: theme.palette.custom.orange.contrastText,
    },
    textField: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      backgroundColor: theme.palette.background.entity_highlight_background,
      borderRadius: "10px",
      border: "none",
      [`& fieldset`]: {
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    weeklySelectedDay: {
      backgroundColor: theme.palette.custom.green.main,
      minWidth: "40px",
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      maxWidth: "40px",
      color: "white",
      "&:hover": {
        backgroundColor: theme.palette.custom.green.main,
      },
    },
    chip: {
      backgroundColor: theme.palette.background.entity_highlight_background,
    },
    weeklyNotSelectedDay: {
      border: `1px solid ${theme.palette.secondary.main}`,
      minWidth: "40px",
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      maxWidth: "40px",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    chipContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: "8px",
    },
  }),
);

const executionTypeNode = [
  {
    id: "daily",
    name: "Daily",
  },
  {
    id: "weekly",
    name: "Weekly",
  },
  {
    id: "monthly",
    name: "Monthly",
  },
];

const generateDailyTimeNode = () => {
  const dailyTimeNode = [];
  const intervals = 15; // 15-minute intervals
  const totalMinutes = 24 * 60; // 1440 minutes (24 hours)

  for (let id = 0; id <= totalMinutes; id += intervals) {
    const hours24 = Math.floor(id / 60); // Hours in 24-hour format
    const minutes = id % 60;

    // Convert to 12-hour format
    const hours12 = hours24 % 12 === 0 ? 12 : hours24 % 12; // Convert 0 and 12-hour cases correctly
    const period = hours24 < 12 ? "am" : "pm"; // Determine AM/PM

    // Format hours and minutes with leading zeros
    const displayHours =
      hours12 < 10
        ? `0${hours12}`
        : `${
            id === 1440 || id === 0 || id === 15 || id === 30 || id === 45
              ? "00"
              : hours12
          }`;
    const displayMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

    // Construct formatted time string
    const formattedTime = `${displayHours}:${displayMinutes} ${period}`;

    // Push the time node to the array
    dailyTimeNode.push({
      id: id,
      name: formattedTime,
    });
  }

  return dailyTimeNode;
};

const days = [
  { id: 1, name: "M" },
  { id: 2, name: "T" },
  { id: 3, name: "W" },
  { id: 4, name: "T" },
  { id: 5, name: "F" },
  { id: 6, name: "S" },
  { id: 7, name: "S" },
];

const daysObj: any = {
  0: "0",
  1: "M",
  2: "T",
  3: "W",
  4: "T",
  5: "F",
  6: "S",
  7: "S",
};

function getOrdinal(number: any) {
  // Special case for numbers 11, 12, and 13 (the "teen" rule)
  if (number >= 11 && number <= 13) {
    return `${number}th`;
  }

  // Determine the suffix for other numbers
  const lastDigit = number % 10;
  let suffix;

  if (lastDigit === 1) {
    suffix = "st";
  } else if (lastDigit === 2) {
    suffix = "nd";
  } else if (lastDigit === 3) {
    suffix = "rd";
  } else {
    suffix = "th";
  }

  return `${number}${suffix}`;
}

export const getDateObject = (input: any) => {
  if (input < 1 || input > 28) {
    return null; // Ensure input is between 1 and 28
  }

  return {
    id: input,
    name: getOrdinal(input),
  };
};

const month = Array.from({ length: 28 }, (_, i) => ({
  id: i + 1,
  name: `${i + 1}${getOrdinalSuffix(i + 1)}`,
}));

function getOrdinalSuffix(number: any) {
  const suffixes = ["th", "st", "nd", "rd"];
  const value = number % 100;
  return suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0];
}

const dailyTimeNode = generateDailyTimeNode();

const typeNode = [
  {
    id: "daily-summary-email",
    name: "Summary Email",
  },
];

const typeObj: any = {
  "daily-summary-email": {
    id: "daily-summary-email",
    name: "Summary Email",
  },
};

export const generateDailyTimeNodeUsingMinutes = (input: any) => {
  // Define time objects for every 15 minutes
  const times = [];
  const timeFormat = (minutes: any) => {
    const hour = Math.floor(minutes / 60);
    const minute = minutes % 60;
    const period = hour >= 12 ? "pm" : "am";
    const hour12 = hour % 12 === 0 ? 12 : hour % 12;
    const formattedMinute = minute < 10 ? `0${minute}` : minute;
    return `${
      hour === 0 ||
      input === 1440 ||
      input === 15 ||
      input === 30 ||
      input === 45
        ? "00"
        : hour12 < 10
        ? `0${hour12}`
        : hour12
    }:${formattedMinute} ${period}`;
  };

  // Generate time objects for every 15 minutes from 0 to 1440
  for (let i = 0; i <= 1440; i += 15) {
    times.push({ id: i, name: timeFormat(i) });
  }

  // Find the closest time object
  let closest = times[0];
  let minDiff = Math.abs(input - times[0].id);

  for (let i = 1; i < times.length; i++) {
    let diff = Math.abs(input - times[i].id);
    if (diff < minDiff) {
      minDiff = diff;
      closest = times[i];
    }
  }
  return closest;
};

const areListsEqual = (list1: any, list2: any) => {
  // Check if lengths are different
  if (list1.length !== list2.length) {
    return false;
  }

  // Sort both lists and compare each element
  const sortedList1 = [...list1].sort();
  const sortedList2 = [...list2].sort();

  return sortedList1.every((value, index) => value === sortedList2[index]);
};

export interface StockLevelModalProps {
  isOpen: any;
  setIsOpen: any;
  isLoading: any;
  editScheduleDetails: any;
  handleUpdateSchedule: any;
}

const ScheduleEditModal: React.FC<StockLevelModalProps> = ({
  isOpen,
  setIsOpen,
  isLoading,
  editScheduleDetails,
  handleUpdateSchedule,
}) => {
  const [open, setOpen] = useState(false);
  const [openDailyDropDown, setOpenDailyDropDown] = useState(false);
  const [openMonthlyDropDown, setOpenMonthlyDropDown] = useState(false);
  const [typeDropDown, setTypeDropDown] = useState(false);

  const [emailChips, setEmailChips] = useState<string[]>([]);
  const [isExecuteAfterShiftEnd, setIsExecuteAfterShiftEnd] = useState(false);
  const [isTakings, setIsTakings] = useState(false);
  const [isChannels, setIsChannels] = useState(false);
  const [isPlatforms, setIsPlatforms] = useState(false);
  const [isBasedOnShift, setIsBasedOnShift] = useState(false);
  const [isPrevDate, setIsPrevDate] = useState(false);
  const [isModes, setIsModes] = useState(false);
  const [isPaymentTypes, setIsPaymentTypes] = useState(false);
  const [isDiscounts, setIsDiscounts] = useState(false);
  const [isBanking, setIsBanking] = useState(false);
  const [isPayInOuts, setIsPayInOuts] = useState(false);
  const [isCharts, setIsCharts] = useState(false);
  const [email, setEmail] = useState("");
  const [executionType, setExecutionType] = useState<any>({});
  const [dailyTime, setDailyTime] = useState<any>({});
  const [selectedDay, setSelectedDay] = useState<any>({});
  const [selectedMonth, setSelectedMonth] = useState<any>({});
  const [selectedType, setSelectedType] = useState<any>({});

  const [isBasedOnShiftInitial, setIsBasedOnShiftInitial] = useState(false);
  const [isPrevDateInitial, setIsPrevDateInitial] = useState(false);
  const [emailChipsInitial, setEmailChipsInitial] = useState<string[]>([]);
  const [isExecuteAfterShiftEndInitial, setIsExecuteAfterShiftEndInitial] =
    useState(false);
  const [isTakingsInitial, setIsTakingsInitial] = useState(false);
  const [isChannelsInitial, setIsChannelsInitial] = useState(false);
  const [isPlatformsInitial, setIsPlatformsInitial] = useState(false);
  const [isModesInitial, setIsModesInitial] = useState(false);
  const [isPaymentTypesInitial, setIsPaymentTypesInitial] = useState(false);
  const [isDiscountsInitial, setIsDiscountsInitial] = useState(false);
  const [isBankingInitial, setIsBankingInitial] = useState(false);
  const [isPayInOutsInitial, setIsPayInOutsInitial] = useState(false);
  const [isChartsInitial, setIsChartsInitial] = useState(false);
  const [emailInitial, setEmailInitial] = useState("");
  const [executionTypeInitial, setExecutionTypeInitial] = useState<any>({});
  const [dailyTimeInitial, setDailyTimeInitial] = useState<any>({});
  const [selectedDayInitial, setSelectedDayInitial] = useState<any>({});
  const [selectedMonthInitial, setSelectedMonthInitial] = useState<any>({});
  const [selectedTypeInitial, setSelectedTypeInitial] = useState<any>({});
  const [isBasedOnShiftDate, setIsBasedOnShiftDate] = useState(true);
  const [isBasedOnShiftDateInitial, setIsBasedOnShiftDateInitial] =
    useState(true);

  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const match: any = useRouteMatch();

  useEffect(() => {
    if (isOpen && Object.values(editScheduleDetails).length > 0) {
      const { data, executeTime, executionDay, executionType, type } =
        editScheduleDetails;
      const scheduledData = JSON.parse(data);
      if (scheduledData.basedOnShift?.toString()) {
        setIsBasedOnShift(scheduledData.basedOnShift);
      }
      if (scheduledData.prevDate?.toString()) {
        setIsPrevDate(scheduledData.prevDate);
      }
      setIsBasedOnShiftDate(scheduledData.basedOnShift);
      setIsBasedOnShiftDateInitial(scheduledData.basedOnShift);
      setEmailChips(scheduledData.recipients);
      setIsExecuteAfterShiftEnd(scheduledData.executeAfterShiftEnd);
      setIsTakings(scheduledData.sections.takings);
      setIsChannels(scheduledData.sections.channels);
      setIsPlatforms(scheduledData.sections.platforms);
      setIsModes(scheduledData.sections.modes);
      setIsPaymentTypes(scheduledData.sections.paymentTypes);
      setIsDiscounts(scheduledData.sections.discounts);
      setIsBanking(scheduledData.sections.banking);
      setIsPayInOuts(scheduledData.sections.payInOuts);
      setIsCharts(scheduledData.sections.charts);
      setEmailErrorMessage("");
      setIsError(false);
      setEmail("");
      setExecutionType({
        id: executionType,
        name: executionType.charAt(0).toUpperCase() + executionType.slice(1),
      });
      setDailyTime(generateDailyTimeNodeUsingMinutes(executeTime));
      setSelectedType(typeObj[type]);

      setIsPrevDateInitial(scheduledData.prevDate);
      if (!scheduledData.basedOnShift?.toString()) {
        setIsBasedOnShiftInitial(scheduledData.basedOnShift);
      }
      setIsExecuteAfterShiftEndInitial(scheduledData.executeAfterShiftEnd);
      setEmailChipsInitial(scheduledData.recipients);
      setIsExecuteAfterShiftEndInitial(scheduledData.executeAfterShiftEnd);
      setIsTakingsInitial(scheduledData.sections.takings);
      setIsChannelsInitial(scheduledData.sections.channels);
      setIsPlatformsInitial(scheduledData.sections.platforms);
      setIsModesInitial(scheduledData.sections.modes);
      setIsPaymentTypesInitial(scheduledData.sections.paymentTypes);
      setIsDiscountsInitial(scheduledData.sections.discounts);
      setIsBankingInitial(scheduledData.sections.banking);
      setIsPayInOutsInitial(scheduledData.sections.payInOuts);
      setIsChartsInitial(scheduledData.sections.charts);
      setExecutionTypeInitial({
        id: executionType,
        name: executionType.charAt(0).toUpperCase() + executionType.slice(1),
      });
      setDailyTimeInitial(generateDailyTimeNodeUsingMinutes(executeTime));
      if (executionType === "weekly") {
        setSelectedDay({ id: executionDay, name: daysObj[executionDay] });
        setSelectedDayInitial({
          id: executionDay,
          name: daysObj[executionDay],
        });
      }

      if (executionType === "monthly") {
        setSelectedMonth(getDateObject(executionDay));
        setSelectedMonthInitial(getDateObject(executionDay));
      }
      setSelectedTypeInitial(typeObj[type]);
    }
    // setDailyWeeklyMonthlyNode()
  }, [isOpen, editScheduleDetails]);

  const handleDisabled = () => {
    if (
      areListsEqual(emailChips, emailChipsInitial) &&
      isExecuteAfterShiftEndInitial === isExecuteAfterShiftEnd &&
      isTakings === isTakingsInitial &&
      isChannels === isChannelsInitial &&
      isPlatforms === isPlatformsInitial &&
      isModes === isModesInitial &&
      isPaymentTypes === isPaymentTypesInitial &&
      isDiscounts === isDiscountsInitial &&
      isBanking === isBankingInitial &&
      isPayInOuts === isPayInOutsInitial &&
      isCharts === isChartsInitial &&
      isPrevDate.toString() === isPrevDateInitial.toString() &&
      isBasedOnShiftDate.toString() === isBasedOnShiftDateInitial.toString() &&
      executionType?.id === executionTypeInitial?.id &&
      dailyTime?.id === dailyTimeInitial?.id &&
      selectedDay?.id === selectedDayInitial?.id &&
      selectedMonth?.id === selectedMonthInitial?.id &&
      selectedType?.id === selectedTypeInitial?.id
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setEmailChips([]);
    setIsExecuteAfterShiftEnd(false);
    setIsTakings(false);
    setIsChannels(false);
    setIsPlatforms(false);
    setIsModes(false);
    setIsPaymentTypes(false);
    setIsDiscounts(false);
    setIsBanking(false);
    setIsPayInOuts(false);
    setIsCharts(false);
    setEmailErrorMessage("");
    setIsError(false);
    setEmail("");
    setExecutionType({});
    setDailyTime({});
    setSelectedDay({});
    setSelectedMonth({});
    setSelectedType({});
  };

  const handleChangeExecutionType = (e: any, executionType: any) => {
    setExecutionType(executionType);
    setSelectedMonth({});
    setSelectedDay({});
  };

  const handleChangeDailyTime = (e: any, executionType: any) => {
    setDailyTime(executionType);
  };

  const handleChangeMonthlyTime = (e: any, month: any) => {
    setSelectedMonth(month);
  };

  const handleChangeType = (e: any, type: any) => {
    setSelectedType(type);
  };

  const handleDayClick = (day: any) => {
    setSelectedDay(day);
  };

  const handleExecuteAfterShiftEnd = () => {
    setIsExecuteAfterShiftEnd(!isExecuteAfterShiftEnd);
  };

  const handleTakings = () => {
    setIsTakings(!isTakings);
  };

  const handleChannels = () => {
    setIsChannels(!isChannels);
  };

  const handleIsPlatforms = () => {
    setIsPlatforms(!isPlatforms);
  };

  const handleIsModes = () => {
    setIsModes(!isModes);
  };

  const handlePaymentTypes = () => {
    setIsPaymentTypes(!isPaymentTypes);
  };

  const handleDiscounts = () => {
    setIsDiscounts(!isDiscounts);
  };

  const handleBanking = () => {
    setIsBanking(!isBanking);
  };

  const handlePayInOuts = () => {
    setIsPayInOuts(!isPayInOuts);
  };

  const handleCharts = () => {
    setIsCharts(!isCharts);
  };

  const handleBasedOnShift = () => {
    setIsBasedOnShift(!isBasedOnShift);
  };

  const handlePrevDate = () => {
    setIsPrevDate(!isPrevDate);
  };

  const handleBasedOnShiftDate = () => {
    setIsBasedOnShiftDate(!isBasedOnShiftDate);
  };

  const handleAdEmailChip = () => {
    if (!EMAIL_PATTERN.test(email)) {
      setIsError(true);
      setEmailErrorMessage("Invalid Email.");
      return;
    }

    if (emailChips.includes(email)) {
      setIsError(true);
      setEmailErrorMessage("This email has already been added.");
      return;
    }
    setEmailChips((prev) => [...prev, email]);
    setEmail("");
    setEmailErrorMessage("");
  };

  const handleChipDelete = (chipToDelete: string) => {
    setEmailChips((prev) => prev.filter((chip) => chip !== chipToDelete));
  };

  const handleEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const handleCreate = () => {
    if (
      !executionType?.id ||
      !dailyTime?.id?.toString() ||
      (executionType?.id === "weekly" && !selectedDay?.id) ||
      (executionType.id === "monthly" && !selectedMonth?.id) ||
      !selectedType?.id
    ) {
      setIsError(true);
    } else {
      if (
        selectedType.id === "daily-summary-email" &&
        emailChips.length === 0 &&
        !email
      ) {
        setEmailErrorMessage("Email is required.");
        setIsError(true);
      } else {
        const emailList = emailChips ? emailChips : [...email];
        if (emailList.length === 0) {
          if (!EMAIL_PATTERN.test(email)) {
            setIsError(true);
            setEmailErrorMessage("Invalid Email.");
            return;
          }
          emailList.push(email);
        }

        const formData = {
          locationId: match.params.locationId,
          executionType: executionType.id,
          executeTime: dailyTime.id,
          type: selectedType.id,
          executionDay:
            executionType?.id === "daily"
              ? 0
              : executionType?.id === "monthly"
              ? selectedMonth?.id
              : selectedDay?.id,
          id: editScheduleDetails.id,
          version: editScheduleDetails.version,
          status: editScheduleDetails.status,
          data: {
            executeAfterShiftEnd: isExecuteAfterShiftEnd,
            basedOnShift: isBasedOnShiftDate,
            prevDate: isPrevDate,
            sections: {
              takings: isTakings,
              channels: isChannels,
              platforms: isPlatforms,
              modes: isModes,
              paymentTypes: isPaymentTypes,
              discounts: isDiscounts,
              banking: isBanking,
              payInOuts: isPayInOuts,
              charts: isCharts,
            },
            recipients: emailList,
          },
        };
        handleUpdateSchedule(formData);
        setIsError(false);
      }
    }
  };

  const classes = useStyles();
  return (
    <DialogCommonDefault
      open={isOpen}
      setOpen={setIsOpen}
      isNeedFixedHeight={false}
      backgroundColor="entity_background"
      maxWidth="sm"
    >
      <DialogTitle>Edit Scheduled Task </DialogTitle>
      <DialogContent>
        <Autocomplete
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          size={"small"}
          id="locationSelectGlobal"
          color="inherit"
          options={executionTypeNode}
          value={executionType}
          getOptionLabel={(option: any) => option.name || ""}
          disableClearable
          onChange={handleChangeExecutionType}
          classes={{ root: classes.autoComplete }}
          popupIcon={<ArrowDropDownIcon color={"inherit"} />}
          renderOption={(props: any) => {
            return (
              <Grid
                container
                {...props}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  zIndex: 0,
                  height: "20px",
                  marginBottom: "4px",
                }}
              >
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.titleColor}>
                    {props.name}
                  </Typography>
                </Grid>
              </Grid>
            );
          }}
          renderInput={(params: any) => (
            <TextField
              color="inherit"
              {...params}
              label=""
              placeholder="Execution Type"
              variant="outlined"
            />
          )}
        />

        {isError && !executionType?.id && (
          <ValidationMessage message={"Execution Type is required."} />
        )}

        <Autocomplete
          open={openDailyDropDown}
          onOpen={() => setOpenDailyDropDown(true)}
          onClose={() => setOpenDailyDropDown(false)}
          size={"small"}
          id="locationSelectGlobal"
          color="inherit"
          options={dailyTimeNode}
          value={dailyTime}
          getOptionLabel={(option: any) => option.name || ""}
          disableClearable
          style={{ marginTop: "20px" }}
          onChange={handleChangeDailyTime}
          classes={{ root: classes.autoComplete }}
          popupIcon={<ArrowDropDownIcon color={"inherit"} />}
          renderOption={(props: any) => {
            return (
              <Grid
                container
                {...props}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  zIndex: 0,
                  height: "20px",
                  marginBottom: "4px",
                }}
              >
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.titleColor}>
                    {props.name}
                  </Typography>
                </Grid>
              </Grid>
            );
          }}
          renderInput={(params: any) => (
            <TextField
              color="inherit"
              {...params}
              label=""
              placeholder="Execution Time"
              variant="outlined"
            />
          )}
        />

        {isError && !dailyTime?.id?.toString() && (
          <ValidationMessage message={"Execution Time is required."} />
        )}

        <div style={{ marginTop: "20px" }} />
        {executionType.id === "weekly" && (
          <>
            <Grid container spacing={1} style={{ flexWrap: "nowrap" }}>
              {days.map((day: any, index: number) => (
                <Grid item style={{ flex: "1 1 auto" }}>
                  {/* <div style={{display: "flex", justifyContent: index===0?"start": index===6 ? "end" :"center"}}> */}
                  <Button
                    fullWidth
                    onClick={() => handleDayClick(day)}
                    className={
                      selectedDay.id === day.id
                        ? classes.weeklySelectedDay
                        : classes.weeklyNotSelectedDay
                    }
                  >
                    {day.name}
                  </Button>
                  {/* </div> */}
                </Grid>
              ))}
            </Grid>
            <div style={{ marginTop: "4px" }} />
            {isError && !selectedDay?.id && (
              <ValidationMessage message={"Date is required."} />
            )}
          </>
        )}

        {executionType.id === "monthly" && (
          <>
            <Autocomplete
              open={openMonthlyDropDown}
              onOpen={() => setOpenMonthlyDropDown(true)}
              onClose={() => setOpenMonthlyDropDown(false)}
              size={"small"}
              id="locationSelectGlobal"
              color="inherit"
              options={month}
              value={selectedMonth}
              getOptionLabel={(option: any) => option.name || ""}
              disableClearable
              onChange={handleChangeMonthlyTime}
              classes={{ root: classes.autoComplete }}
              popupIcon={<ArrowDropDownIcon color={"inherit"} />}
              renderOption={(props: any) => {
                return (
                  <Grid
                    container
                    {...props}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      zIndex: 0,
                      height: "20px",
                      marginBottom: "4px",
                    }}
                  >
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        className={classes.titleColor}
                      >
                        {props.name}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              }}
              renderInput={(params: any) => (
                <TextField
                  color="inherit"
                  {...params}
                  label=""
                  placeholder="Month Time"
                  variant="outlined"
                />
              )}
            />
            {isError && !selectedMonth?.id && (
              <ValidationMessage message={"Month is required."} />
            )}
          </>
        )}
        <Grid container style={{ placeItems: "center" }}>
          <Grid item xs={12}>
            <Autocomplete
              open={typeDropDown}
              onOpen={() => setTypeDropDown(true)}
              onClose={() => setTypeDropDown(false)}
              size={"small"}
              id="locationSelectGlobal"
              color="inherit"
              options={typeNode}
              value={selectedType}
              getOptionLabel={(option: any) => option.name || ""}
              disableClearable
              onChange={handleChangeType}
              classes={{ root: classes.autoComplete }}
              popupIcon={<ArrowDropDownIcon color={"inherit"} />}
              renderOption={(props: any) => {
                return (
                  <Grid
                    container
                    {...props}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      zIndex: 0,
                      height: "20px",
                      marginBottom: "4px",
                    }}
                  >
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        className={classes.titleColor}
                      >
                        {props.name}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              }}
              renderInput={(params: any) => (
                <TextField
                  color="inherit"
                  {...params}
                  label=""
                  placeholder="Type"
                  variant="outlined"
                />
              )}
            />
            {isError && !selectedType?.id && (
              <ValidationMessage message={"Type is required."} />
            )}
          </Grid>
          <Grid item xs={12} style={{ marginTop: "12px", marginBottom: "4px" }}>
            <FormControlLabel
              style={{ marginRight: "0px", marginLeft: "0px" }}
              control={<SwitchCommon />}
              checked={isPrevDate}
              onChange={handlePrevDate}
              label="Generate Based on Previous Date"
              labelPlacement="start"
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: "12px", marginBottom: "4px" }}>
            <FormControlLabel
              style={{ marginRight: "0px", marginLeft: "0px" }}
              control={<SwitchCommon />}
              checked={isBasedOnShiftDate}
              onChange={handleBasedOnShiftDate}
              label="Generate Based on Shift’s Dates"
              labelPlacement="start"
            />
          </Grid>
        </Grid>

        {selectedType.id === "daily-summary-email" && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">Summary Sections</Typography>
            </Grid>

            <Grid item xs={4}>
              <CardCommon
                backgroundColor={"entity_background"}
                style={{ height: "80px" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "7px",
                  }}
                >
                  <FormControlLabel
                    style={{ marginRight: "0px", marginLeft: "0px" }}
                    control={<SwitchCommon />}
                    checked={isTakings}
                    onChange={handleTakings}
                    label={<Typography variant="caption">Takings</Typography>}
                    labelPlacement="top"
                  />
                </div>
              </CardCommon>
            </Grid>

            <Grid item xs={4}>
              <CardCommon
                backgroundColor={"entity_background"}
                style={{ height: "80px" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "7px",
                  }}
                >
                  <FormControlLabel
                    style={{ marginRight: "0px", marginLeft: "0px" }}
                    control={<SwitchCommon />}
                    checked={isChannels}
                    onChange={handleChannels}
                    label={<Typography variant="caption">Channels</Typography>}
                    labelPlacement="top"
                  />
                </div>
              </CardCommon>
            </Grid>

            <Grid item xs={4}>
              <CardCommon
                backgroundColor={"entity_background"}
                style={{ height: "80px" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "7px",
                  }}
                >
                  <FormControlLabel
                    style={{ marginRight: "0px", marginLeft: "0px" }}
                    control={<SwitchCommon />}
                    checked={isModes}
                    onChange={handleIsModes}
                    label={<Typography variant="caption">Modes</Typography>}
                    labelPlacement="top"
                  />
                </div>
              </CardCommon>
            </Grid>

            <Grid item xs={4}>
              <CardCommon
                backgroundColor={"entity_background"}
                style={{ height: "80px" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "7px",
                  }}
                >
                  <FormControlLabel
                    style={{ marginRight: "0px", marginLeft: "0px" }}
                    control={<SwitchCommon />}
                    checked={isPlatforms}
                    onChange={handleIsPlatforms}
                    label={<Typography variant="caption">Platforms</Typography>}
                    labelPlacement="top"
                  />
                </div>
              </CardCommon>
            </Grid>

            <Grid item xs={4}>
              <CardCommon
                backgroundColor={"entity_background"}
                style={{ height: "80px" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "7px",
                  }}
                >
                  <FormControlLabel
                    style={{ marginRight: "0px", marginLeft: "0px" }}
                    control={<SwitchCommon />}
                    checked={isBanking}
                    onChange={handleBanking}
                    label={<Typography variant="caption">Banking</Typography>}
                    labelPlacement="top"
                  />
                </div>
              </CardCommon>
            </Grid>
            <Grid item xs={4}>
              <CardCommon
                backgroundColor={"entity_background"}
                style={{ height: "80px" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "7px",
                  }}
                >
                  <FormControlLabel
                    style={{ marginRight: "0px", marginLeft: "0px" }}
                    control={<SwitchCommon />}
                    checked={isPayInOuts}
                    onChange={handlePayInOuts}
                    label={
                      <Typography variant="caption">Paid In/Outs</Typography>
                    }
                    labelPlacement="top"
                  />
                </div>
              </CardCommon>
            </Grid>

            <Grid item xs={4}>
              <CardCommon
                backgroundColor={"entity_background"}
                style={{ height: "80px" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "7px",
                  }}
                >
                  <FormControlLabel
                    style={{ marginRight: "0px", marginLeft: "0px" }}
                    control={<SwitchCommon />}
                    checked={isCharts}
                    onChange={handleCharts}
                    label={<Typography variant="caption">Charts</Typography>}
                    labelPlacement="top"
                  />
                </div>
              </CardCommon>
            </Grid>

            <Grid item xs={4}>
              <CardCommon
                backgroundColor={"entity_background"}
                style={{ height: "80px" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "7px",
                  }}
                >
                  <FormControlLabel
                    style={{ marginRight: "0px", marginLeft: "0px" }}
                    control={<SwitchCommon />}
                    checked={isDiscounts}
                    onChange={handleDiscounts}
                    label={<Typography variant="caption">Discounts</Typography>}
                    labelPlacement="top"
                  />
                </div>
              </CardCommon>
            </Grid>
          </Grid>
        )}

        {selectedType.id === "daily-summary-email" && (
          <Grid container>
            <Grid item xs={12}>
              <TextfieldDefaultNew
                id="postcode"
                name="postcode"
                style={{ width: "100%" }}
                label="Email"
                onChange={handleEmail}
                value={email}
                type="text"
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleAdEmailChip();
                  }
                }}
              />
              {emailErrorMessage && isError && (
                <ValidationMessage message={emailErrorMessage} />
              )}
            </Grid>
            <Grid item xs={12}>
              <div className={classes.chipContainer}>
                {emailChips.map((chip, index) => (
                  <Chip
                    key={index}
                    label={chip}
                    onDelete={() => handleChipDelete(chip)}
                    className={classes.chip}
                  />
                ))}
              </div>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions style={{ marginBottom: "12px" }}>
        <ButtonCommon
          onClick={handleClose}
          variant="contained"
          disabled={isLoading}
          style={{ fontSize: 11, width: "120px", marginRight: "12px" }}
          color={buttonColors.CANCEL_BUTTON_COLOR}
        >
          Cancel
        </ButtonCommon>

        <ButtonCommon
          onClick={handleCreate}
          variant="contained"
          disabled={isLoading || handleDisabled()}
          style={{
            fontSize: 11,
            width: "120px",
            marginRight: "14px",
            color: "white",
          }}
          color={buttonColors.CREATE_BUTTON_COLOR}
        >
          {isLoading ? (
            <CircularProgress size={20} color="secondary" />
          ) : (
            "Save"
          )}
        </ButtonCommon>
      </DialogActions>
    </DialogCommonDefault>
  );
};

export default ScheduleEditModal;
