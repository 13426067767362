import React, { useState } from "react";
import {
  createStyles,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import _ from "lodash";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import CardCommon from "../../../../components/card/CardCommon";
import { Autocomplete } from "@material-ui/lab";
import SwitchCommon from "../../../../components/switches/SwitchCommon";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import { buttonColors } from "../../../../utils/enum";
import { useRouteMatch } from "react-router-dom";
import AlertDialog from "../../../../components/alerts/AlertDialog";
import TextfieldCommon from "../../../../components/textField/TextfieldCommon";

export interface StockConfigurationNodeProps {
  locationSelectorList: any;
  setSelectedDepartmentObj: any;
  selectedDepartmentObj: any;
  setIsActive: any;
  isActive: any;
  stockConfiguration: any;
  handleGetMasterLocationSpecificLocation: any;
  selectedDepartmentObjInitial: any;
  isActiveInitial: any;
  setIsEnableMasterLocation: any;
  isEnableMasterLocation: any;
  isLoadingButton: any;
  isDisabledButton: any;
  playStoreUrl: any;
  appStoreUrl: any;
  setPlayStoreUrl: any;
  setAppStoreUrl: any;
  playStoreUrlInitial: any;
  appStoreUrlInitial: any;
  isAvailableOnline: any;
  isAvailableOnlineInitial: any;
  setIsAvailableOnline: any;
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    titleColor: {
      color: theme.palette.custom.orange.contrastText,
    },
    autoComplete: {
      marginTop: "4px",
      "& .MuiAutocomplete-input": {
        color: theme.palette.custom.orange.contrastText,
      },
      "& .MuiInputBase-root": {
        color: "inherit",
        backgroundColor: theme.palette.background.entity_highlight_background,
        borderRadius: 10,
      },
      [`& fieldset`]: {
        borderRadius: 10,
        border: "none",
        color: theme.palette.custom.orange.contrastText,
      },
    },
  }),
);

/**
 * Component: LoyaltyConfigurationNode
 *
 * Description:
 * This component represents a node in the Stock Configuration page.
 * It provides controls for managing stock-related configurations, such as enabling/disabling stock,
 * enabling/disabling master location, selecting the department, and saving changes.
 **/

const LoyaltyConfigurationNode: React.FunctionComponent<
  StockConfigurationNodeProps
> = ({
  locationSelectorList,
  setSelectedDepartmentObj,
  selectedDepartmentObj,
  setIsActive,
  isActive,
  stockConfiguration,
  handleGetMasterLocationSpecificLocation,
  selectedDepartmentObjInitial,
  isActiveInitial,
  setIsEnableMasterLocation,
  isEnableMasterLocation,
  isLoadingButton,
  isDisabledButton,
  playStoreUrl,
  appStoreUrl,
  setPlayStoreUrl,
  setAppStoreUrl,
  playStoreUrlInitial,
  appStoreUrlInitial,
  isAvailableOnline,
  isAvailableOnlineInitial,
  setIsAvailableOnline,
}) => {
  const [open, setOpen] = useState(false);
  const [isOpeAlertModal, setIsOpenAlertModal] = useState(false);

  const classes = useStyles();
  const match: any = useRouteMatch();

  /**
   * Function: handleChangeMasterLocation
   *
   * Description:
   * This function handles the change event when selecting a master location in the Autocomplete component.
   * It checks if the selected master location's ID matches the current location ID. If so, it disables the master location
   * and sets a default value for the selected department. Otherwise, it sets the selected department based on the
   * chosen master location.
   **/
  const handleChangeMasterLocation = (e: any, masterLocationData: any) => {
    // Check if the selected master location's ID matches the current location ID
    if (masterLocationData.id === match.params.locationId) {
    } else {
      // Set the selected department based on the chosen master location
      setSelectedDepartmentObj(masterLocationData);
    }
  };

  /**
   * Function: handleActivateLoyalty
   *
   * Description:
   * This function handles the activation or deactivation of stock based on the user's interaction with the switch.
   * It extracts the 'checked' property from the event object and sets the 'isActive' state accordingly.
   **/
  const handleActivateLoyalty = (e: any) => {
    // Extract the 'checked' property from the event object
    const { checked } = e.target;

    // Set the 'isActive' state based on the user's interaction
    setIsActive(checked);
  };

  const handleIsAvailableOnline = (e: any) => {
    // Extract the 'checked' property from the event object
    const { checked } = e.target;

    // Set the 'isActive' state based on the user's interaction
    setIsAvailableOnline(checked);
  };

  /**
   * Function: handleEnableMasterLocation
   *
   * Description:
   * This function handles the enabling or disabling of the Master Location based on the user's interaction with the switch.
   * It extracts the 'checked' property from the event object and sets the 'isEnableMasterLocation' state accordingly.
   * If Master Location is being disabled, it sets the 'selectedDepartmentObj' state to indicate that Master Location is not required.
   **/
  const handleEnableMasterLocation = (e: any) => {
    // Extract the 'checked' property from the event object
    const { checked } = e.target;

    // Set the 'isEnableMasterLocation' state based on the user's interaction
    setIsEnableMasterLocation(checked);

    // If Master Location is being disabled, update the 'selectedDepartmentObj' state
    if (!checked) {
      setSelectedDepartmentObj({
        id: match.params.locationId,
        label: "Master Location Not Required",
      });
    }

    setIsOpenAlertModal(true);
  };

  /**
   * Function: handleSubmit
   *
   * Description:
   * This function is triggered when the user clicks the "Save" button. It calls the 'handleGetMasterLocationSpecificLocation' function,
   * passing the 'id' of the selected department as an argument.
   **/
  const handleSubmit = () => {
    handleGetMasterLocationSpecificLocation(selectedDepartmentObj.id);
  };
  const filteredOptions = locationSelectorList.filter(
    (option: any) => option.id !== match.params.locationId,
  );

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6} xl={5}>
          <CardCommon backgroundColor={"entity_background"}>
            {/* <CardCommon color={handleBackgroundColor(isActive)}> */}
            <div style={{ marginTop: "20px" }}></div>
            <Grid item xs={12} style={{ marginRight: "38px" }}>
              <CardCommon
                backgroundColor={"entity_highlight_background"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0px 20px 20px 20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    margin: "12px 4px",
                    width: "100%",
                  }}
                >
                  <FormControlLabel
                    style={{ marginRight: "0px" }}
                    control={<SwitchCommon />}
                    checked={isActive}
                    disabled={isDisabledButton}
                    onChange={handleActivateLoyalty}
                    label="Enable Loyalty"
                    labelPlacement="start"
                  />

                  <FormControlLabel
                    style={{ marginRight: "0px" }}
                    control={<SwitchCommon />}
                    checked={isAvailableOnline}
                    disabled={isDisabledButton}
                    onChange={handleIsAvailableOnline}
                    label="Available Online"
                    labelPlacement="start"
                  />

                  <FormControlLabel
                    style={{ marginRight: "0px" }}
                    control={<SwitchCommon />}
                    checked={isEnableMasterLocation}
                    onChange={handleEnableMasterLocation}
                    disabled={isDisabledButton && isEnableMasterLocation}
                    label="Change Loyalty Program"
                    labelPlacement="start"
                  />
                </div>
              </CardCommon>
            </Grid>
            <div style={{ margin: "20px", display: "flex" }}>
              <TextfieldCommon
                id="sign-email-input"
                name="sign-email-input"
                style={{ width: "100%", marginRight: "15px" }}
                label="App Store URL"
                disabled={isDisabledButton}
                onChange={(e: any) => setAppStoreUrl(e.target.value)}
                value={appStoreUrl}
                type="text"
              />

              <TextfieldCommon
                id="sign-email-input"
                name="sign-email-input"
                style={{ width: "100%", marginLeft: "15px" }}
                label="Play Store URL"
                disabled={isDisabledButton}
                onChange={(e: any) => setPlayStoreUrl(e.target.value)}
                value={playStoreUrl}
                type="text"
              />
            </div>
            <div style={{ margin: "20px" }}>
              <Autocomplete
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                size={"small"}
                id="locationSelectGlobal"
                color="inherit"
                value={selectedDepartmentObj}
                getOptionLabel={(option: any) => option.label}
                options={filteredOptions}
                disableClearable
                disabled={!isEnableMasterLocation}
                onChange={handleChangeMasterLocation}
                classes={{ root: classes.autoComplete }}
                popupIcon={<ArrowDropDownIcon color={"inherit"} />}
                renderOption={(props: any) => {
                  if (props.id === match.params.locationId) {
                    return null;
                  }

                  return (
                    <Grid
                      container
                      {...props}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        zIndex: 0,
                        height: "20px",
                        marginBottom: "4px",
                      }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          variant="body2"
                          className={classes.titleColor}
                        >
                          {props.label}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                }}
                renderInput={(params: any) => (
                  <TextField
                    color="inherit"
                    {...params}
                    label=""
                    placeholder="Select Location"
                    variant="outlined"
                  />
                )}
              />
            </div>

            <Grid item xs={12} style={{ width: "100%" }}>
              <div style={{ marginRight: "34px" }}>
                <ButtonCommon
                  onClick={handleSubmit}
                  variant="contained"
                  style={{
                    fontSize: 11,
                    margin: "0px 60px 20px 20px",
                    width: "100%",
                  }}
                  disabled={
                    isActive === isActiveInitial &&
                    selectedDepartmentObj.id ===
                      selectedDepartmentObjInitial.id &&
                    playStoreUrl === playStoreUrlInitial &&
                    appStoreUrl === appStoreUrlInitial &&
                    isAvailableOnline === isAvailableOnlineInitial
                  }
                  color={
                    !_.isEmpty(stockConfiguration)
                      ? buttonColors.UPDATE_BUTTON_COLOR
                      : buttonColors.CREATE_BUTTON_COLOR
                  }
                  isLoadingPage={isLoadingButton}
                >
                  {"Save"}
                </ButtonCommon>
              </div>
            </Grid>
          </CardCommon>
        </Grid>
      </Grid>

      {isOpeAlertModal && (
        <AlertDialog
          open={isOpeAlertModal}
          title="Success"
          desc="You are about to select a program for the another location"
          severity="success"
          confirmLabel="Ok"
          cancelLabel={"Cancel"}
          cancelAction={() => setIsOpenAlertModal(false)}
          disableBackdropClick
          confirmAction={() => {
            setIsOpenAlertModal(false);
          }}
          color="secondary"
        />
      )}
    </>
  );
};

// Apply the WithLoading higher-order component to the LoyaltyConfigurationNode component
export default LoyaltyConfigurationNode;
